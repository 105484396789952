<template class="About">
  <nav>
    <router-link class="nav" to="/home">Home</router-link> | |
    <router-link class="nav" to="/about">About</router-link> 
  </nav>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>
<style>
</style>
