import cookie from 'js-cookie';
import { createStore } from 'vuex';

export default createStore({
  state: {
    userdata: null
  },
  mutations: {
    SET_LOGIN(state, payload){
      state.userdata = payload
      alert("Login Successful")
    },
    SET_LOGOUT(state) {
      cookie.remove('userdata')
      state.userdata = null;
      
    }
  },
  actions: {
    logout({commit}, router) {
      commit('SET_LOGOUT')
      router.push({name: 'login'})
    }
  },
})
