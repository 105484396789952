
<template class="home">
<nav>
  <router-link class="nav" to="/home">Home</router-link> | |
  <router-link class="nav" to="/about">About</router-link> 
  <button @click="logout" class="btn-logout">logout</button>
</nav>
  <div class="home">
    <img class="img-fto" src="../assets/earth.png"/>
  </div>
  <h1>LIST DATA PRODUCT</h1>
  <br>
  <table class="table table-list">
    <thead class="table-head">
      <tr>
        <th >
         <td>No</td>
        </th>
        <th>
          <td>images</td>
        </th>
        <th>
          <td>Name</td>
        </th>
        <th>
          <td>Description</td>
        </th>
        <th>
          <td>Category</td>
        </th>
        <th>
          <td>Price</td>
        </th>
        <th>
          <td>action</td>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in dataProduct" :key="index">
        <td>{{ index + 1 }}</td>
        <td><img :src='item.images' style="width: 50px;" alt="" class="img-home"/></td>
        <td>{{ item.description }}</td>
        <td>{{ item.category }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.price }}</td>
        <td><button class="btn-list" @click="edited(item)">edit</button></td>
      </tr>
    </tbody>
  </table>

</template>
<script>
export default {
  data () {
    return {
      dataProduct: [],
      dataprouctlimit: '30',
    };
  },
  computed:{

  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.getListDataProduct();
  },
  methods: {
    logout () {
    this.$store.dispatch('logout', this.$router)
   },
    getListDataProduct() {
      this.$axios.get('products?limit=0&offset=10')
      .then(res => {
        let productdata = res.data
        console.log('ini data asli', productdata);
        let img = productdata[0]?.images[0];
        console.log('ini images', img)
        productdata.forEach(item => {
          this.dataProduct.push(
            {
            'id': item.id,
            'categoryId': item.category?.id,
            'images': item.category?.image,
            'description': item.description,
            'category': item.category?.name,
            'name': item.title,
            'price': item.price
          }
        );
      });
      console.log('data olahan', this.dataProduct)
      })
     },
        edited(item){
          this.$router.push('/table-edit?categoryId=' + item.categoryId + '&id=' + item.id)
        }
    }
  }

</script>