<template class="login">
    <header>
    <div id="login">
        <form @submit.prevent="onSubmit">
        <title>Login</title>
        <img src="../assets/Group 522.png" class="img-log" />
        <div class="form-group">
        <label>Username:</label>
        <input type="email" id="email" v-model="email" required>
      </div><br>
      <div class="form-group">
        <label>Password:</label>
        <input type="password" id="password" v-model="password" required>
      </div>
      <div class="form-group"></div>
      <br>
            <input class="sub" type="submit">
            <br>

        </form>
    </div>
</header>
</template>
<script>
import cookie from 'js-cookie';
export default {
    data() {
        return {
            email: '',
            password: ''
        }
    },
    methods: {
        onSubmit() {
           this.$axios.post("/auth/login", {
                email: this.email,
                password: this.password
            }).then(response => {
                console.log(response);
                this.getDataUser(response.data)
            })
        },
        getDataUser(data){
           this.$axios.get("auth/profile",{
                headers: {
                    Authorization: 'Bearer ' + data.access_token
                }
            }).then(res => {
                console.log(res)
                let userdata =  Object.assign(res.data, data)
                let forcookie =  JSON.stringify(userdata)
                cookie.set("userdata", forcookie, {expires: 1});
                this.$store.commit("SET_LOGIN" , forcookie )
                this.$router.push({path: '/home'})
            })

    },
    }
}
</script>