
<template>
<header v-if="this.$route.path.login">
<nav v-if="this.$store.state.userdate !== null">
  </nav>
</header>
  <router-view/>
</template>
<script>
import cookie from 'js-cookie';
export default {
   
  methods:{
    logout(){
      cookie.remove('userdata')
      this.$store.state.userdata = null
      this.$router.push('/')
      window.location.reload()
    }
  },
  mounted() {
    
  },
}
</script>

<style lang="scss">
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #279e3b;

    &.router-link-exact-active {
      color: #0494f5;
    }
  }
}
</style>
