<template>
    <h1>EDIT PRODUCT</h1>
    <div class="form-edit" style="display: flex; ">
        <div>
        <img class="img-change" :src="this.images" alt="images" />
        <input class="img-edit" type="file" @change="previewFiles">
        </div>
        <input class="input-edit" style="width: 500px;" type="text" v-model="title">
        <input class="input-edit" type="number" v-model="price">
        <textarea name="description" v-model="description"></textarea>
        <button class="btn-edit" type="button" @click.prevent="putData">Submit</button>
    </div>
    <router-link to="/home" ><button class="btn-back">⌂Back To Home⌂</button></router-link>
</template>

<script>
export default {
    data() {
        return {
            title: '',
            price: 0,
            description: '',
            categoryId: '',
            images: ''
        }
    },

    mounted(){
        this.getDataProduct();
    },
    methods: {
        editImages(){
            this.previewFiles()
        },
        // previewFiles(event) {
        //     let formData = new FormData();
        //     let img = event.target.files
        //     formData.append('images',img)
        //     this.$axios.post('files/upload',)
        // },
        getDataProduct(){
            this.$axios.get('products/' + this.$route.query.id)
                .then(res => {
                console.log('res awal', res)
                let data = res.data
                this.title = data.title
                this.price = data.price
                this.description = data.description
                this.images = data.images[0]
            });
        },
        putData(){
        this.$axios.put('products/' + this.$route.query.id, {
             "id": this.$route.query.id,
             "title": this.title,
             "price": this.price,
             "description": this.description,
             "categoryId": this.$route.query.categoryId,
             "images": ['https://akornas.ac.id/wp-content/uploads/2021/12/placeholder.jpg']
        })
            .then(res => {
                if(res.statusText == "OK"){
                    this.$router.push('/home')
                }
            })
        },
        
        }
    }

</script>
<style>
.img-change {
    width: 50px;
    height: 50px;
    position: absolute;
}

.img-edit {
    height: 50px;
    left: 0;
    position: relative;
    overflow: hidden;
    opacity: 0;
    width: 50px;
}
</style>