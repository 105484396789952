import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import Login from '@/views/Login.vue'
import TableEdit from '@/views/EditProduct.vue'
import store from '@/store'
import  register  from '@/views/Register.vue'


const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta:{
      guest: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta:{
      requireAuth: true
    },
  },
  {
    path: '/table-edit',
    name: 'TableEdit',
    component: TableEdit,
    meta:{
      requireAuth: true
  }
},
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta:{
      requireAuth: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta:{
      requireAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (store.state.userdata == null) {
      next("/");
    }
  }
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.state.userdata !== null) {
      next("/home");
    }
  }
  next();
});

export default router
